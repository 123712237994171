import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  Section,
  Span,
  CustomLink,
  StepperComponent,
} from '../components'

import { routes } from 'Routes'

function getSteps() {
  return [
    'Cadastro de conta',
    'Obtenção de chaves de autenticação',
    'Defina o seu ambiente',
  ]
}

function getStepContent() {
  return [
    'Cadastre-se no Superfin para obter acesso à chave de autenticação para os ambientes de sandbox e produção. Entraremos em contato assim que sua conta for aprovada.',
    'A chave de autenticação fica na página de configurações/implementação.',
    'Com as chaves de autenticação, veja como fazer sua primeira requisição no próximo tópico.',
  ]
}

const Introduction = () => {
  const steps = getSteps()
  const stepContents = getStepContent()

  const onPage = [
    ['#d-introducao', 'Introdução'],
    ['#d-primeirosPassos', 'Primeiros passos'],
    ['#d-register', 'Cadastro de conta'],
    ['#d-obtencaoChaves', 'Obtenção de chaves'],
    ['#d-ambientes', 'Defina o ambiente'],
  ]
  // const Code = ({ children }) => {
  //   return (
  //     <span
  //       style={{
  //         backgroundColor: '#e2f4f9',
  //         border: '1px solid #63bbdb',
  //         textTransform: 'lowercase',
  //         padding: '1px',
  //         fontSize: '12px',
  //         fontWeight: 400,
  //         width: 'auto',
  //         borderRadius: '4px',
  //       }}
  //     >
  //       {children}
  //     </span>
  //   )
  // }

  return (
    <Page title="Introdução">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-introducao" subtitle="Começando">
                  Introdução
                </Title>
                <Paragraph>
                  Esta documentação contém as informações necessárias para
                  integrar nossas APIs. Disponibilizamos APIs para os ambientes
                  de sandbox e produção.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-primeirosPassos">
                  Primeiros passos
                </Title>
                <Paragraph>
                  Estes 3 passos são fundamentais para começar a integrar com as
                  APIs do Superfin!
                </Paragraph>
                <StepperComponent steps={steps} stepContents={stepContents} />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-register">
                  Cadastro de conta
                </Title>
                <Paragraph variant="body1">
                  Na página inicial, acesse o link{' '}
                  <CustomLink href="/documentation/introduction">
                    Criar Conta
                  </CustomLink>
                  abaixo do botão de acesso para registrar uma nova conta.
                  Lembre-se de que você precisa aguardar a autorização do
                  cadastro pelo time da Superfin
                </Paragraph>
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-obtencaoChaves">
                  Obtenção de chaves de autenticação
                </Title>
                <Paragraph variant="body1">
                  A chave de autenticação encontra-se na página de
                  <CustomLink href="/documentation/introduction">
                    Implementação
                  </CustomLink>
                  , separada em <Span>seller_key</Span> e{' '}
                  <Span>seller_token</Span>.
                  <br />
                  <br />
                  Com tudo preparado, agora basta realizar as requisições. A
                  Superfin disponibiliza a documentação necessária, incluindo
                  detalhes sobre <Span>seller_key</Span> e{' '}
                  <Span>seller_token</Span>.
                </Paragraph>
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-ambientes">
                  Defina o ambiente
                </Title>
                <Paragraph>
                  O <Span>ambiente de sandbox</Span> é destinado aos
                  desenvolvedores que desejam <b>testar</b> e <b>simular</b> a
                  integração e as requisições. Basicamente, ele é uma cópia do
                  ambiente de produção.
                  <br />
                  <br />O <Span>ambiente de produção</Span> é o ambiente real
                  onde as aplicações são executadas e as transações ocorrem de
                  verdade. Nele, todas as interações e requisições têm impacto
                  direto nos dados e nos serviços oferecidos, sendo necessário
                  garantir a segurança, a eficiência e a integridade das
                  operações.
                </Paragraph>
                {/* <Grid container spacing={8}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <CardImage
                      title="Sandbox"
                      subtitle="Ambiente"
                      backgroundColor="radial-gradient(circle, #3FBEFB 0%, #262DD5 100%)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <CardImage
                      title="Produção"
                      subtitle="Ambiente"
                      backgroundColor="radial-gradient(circle, rgba(212,251,63,1) 0%, rgba(82,213,38,1) 100%)"
                    />
                  </Grid>
                </Grid> */}
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Sandbox"
                    previousName="Início"
                    previousRoute={routes.documentation.main}
                    nextRoute={routes.documentation.sandbox}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Introduction
